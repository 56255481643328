import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

gsap.registerPlugin(ScrollTrigger);

const listWrapperEl = document.querySelector('.top-page_point-section__card-list-wrapper');
const listEl = document.querySelector('.top-page_point-section__card-list');

if (listWrapperEl && listEl) {
  const endValue = listEl.clientWidth - listWrapperEl.clientWidth;

  if (endValue > 0) {
    gsap.to(listEl, {
      x: () => -endValue,
      ease: 'none',
      scrollTrigger: {
        trigger: '.side-scroll',
        start: 'top top',
        end: () => `+=${endValue}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
      },
    });
  }
}
